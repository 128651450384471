import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
// import urljoin from 'url-join';
import Layout from '../components/layout4';
import SEO from '../components/seo';
// import {
//   HonbunWrapper,
// } from './templates.style';
import PostCard from '../components/post-card/post-card';
import Sticky from 'react-stickynode';
import Cityset1 from "../components/citysetting/citytitle7"
import { bar, headerleft, headerno, headertitle, headertitle2, headerright, button,csstabgrid, csstabgridinner, csstabgridchild1, csstabgridchild2, csstabgridchild3, csstabgridchild4, csstabgridchild5, dis2, container, hanrei, brackboard, cityset, cesium, syousai, syousaiheader, csssyousaigridinner, csssyousaigridchild000, table, row1, row2, tablediv, taglinks, tag, tagbutton } from "./blog-post.module.css"
import Hanrei from "../containers/hanrei2"
import CesiumContainer from "../components/cesium/cesiumContainer30"
// import CesiumContainer from "../pages/cesiumContainer20"
import Image from '../components/image';

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  ); 

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};


const BlogPostTemplate = (props: any) => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  const post = props.data.markdownRemark;
  const onChange = event => setValue(event.target.value);
  const city1 = {value};
  const city1nfd = city1.value.normalize( 'NFD' ) ;
  const kml_filenfd = post.frontmatter.kml_file.normalize( 'NFD' ) ;
  // const kml_file = post.frontmatter.kml_file;
  const { edges } = props.data.allMarkdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  // const siteUrl = props.data.site.siteMetadata.siteUrl;
  // const shareUrl = urljoin(siteUrl, slug);
  // const kmlsrc='https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  // const kmldown='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  // const kmlsrc1='https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file

  // const csv='/csv/'+post.frontmatter.no+'/'+ city1nfd + '_' + post.frontmatter.no
  const csv='/csv/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-4)
  
  const kml_kmz='/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd
  
  const kml_kml='/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-1) + 'l'

  // const kml_kmz='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file
  
  // const kml_kml='/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file.substring(0, post.frontmatter.kml_file.length-1) + 'l'

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  };
  var filename="sdg_"+post.frontmatter.sdgs_no+".png";

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
    <Sticky innerZ={9999} activeClass="nav-sticky">
      <div className={bar}>
        <div style={{display:"flex"}}>
          <div className={headerleft}>
            {/* <Cityset1 /> */}
            <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}>
              <Image filename={filename}/>
            </a>
          </div>
          <div className={headerno}>
            {post.frontmatter.no}
          </div>
          <div className={headertitle}>
            {post.frontmatter.title}
          </div>
          <div className={headertitle2}>
            {post.frontmatter.height_name+"×"+post.frontmatter.color_name}
          </div>
          <div className={headerright}>
            <a className={button} href="#">MENU</a>
          </div>
        </div>
      </div>
    </Sticky>







    <div className={csstabgrid}>
      <div className={csstabgridinner}>
        <div className={csstabgridchild1}>
          <Link to={"/"+post.frontmatter.no+"/a/#t"} className={dis2}>
            <h3>自分で地域を選ぶ</h3>
          </Link>
        </div>
        <div className={csstabgridchild2}>
          <Link to={"/"+post.frontmatter.no+"/b/#t"} className={dis2}>
            <h3>地域の昔と比べる</h3>
          </Link>
        </div>
        <div className={csstabgridchild3}>
          <Link to={"/"+post.frontmatter.no+"/c/#t"} className={dis2}>
            <h3>他の地域と比べる</h3>
          </Link>
        </div>
        <div className={csstabgridchild4}>
          <Link to={"/"+post.frontmatter.no+"/d/#t"} className={dis2}>
            <h3>典型例とその解説</h3>
          </Link>
        </div>
        <div className={csstabgridchild5}>
          <Link to={"/"+post.frontmatter.no+"/e/#t"} className={dis2}>
            <h3>国土全体で見る</h3>
          </Link>
        </div>
      </div>
    </div>




<div className={container}>
	<div className={hanrei}>
      <Hanrei
        frontmatter={post.frontmatter}
      />
    <div className={brackboard} >
      TRY!
      <br/>
      {post.frontmatter.try}
    </div>
  </div>

{/* 
	<div className={cityset}>
    <Cityset1
      kml_kmz={kml_kmz}
     />
  </div> */}

	<div className={cesium}>
    <CesiumContainer
      year1={post.frontmatter.year1}
      year2={post.frontmatter.year2}
      year3={post.frontmatter.year3}
      year4={post.frontmatter.year4}
      year5={post.frontmatter.year5}
      csv={csv}
      csvmode={post.frontmatter.csvmode}
      kml_kml={kml_kml}
      kml_kmz={kml_kmz}
      // dir="/kml/sample.kml"
      width="70vw"
      height="65vh"
      no={post.frontmatter.no}
      full_name="全画面表示"
      tofull_url={"/"+post.frontmatter.no+"/kokudof"}
      full_url={"/"+post.frontmatter.no+"/f"}
      min={post.frontmatter.min}
      ratio={post.frontmatter.ratio}
      shikii1={post.frontmatter.shikii1}
      shikii2={post.frontmatter.shikii2}
      shikii3={post.frontmatter.shikii3}
      hanrei1_color={post.frontmatter.hanrei1_color}
      hanrei2_color={post.frontmatter.hanrei2_color}
      hanrei3_color={post.frontmatter.hanrei3_color}
      hanrei4_color={post.frontmatter.hanrei4_color}
      kml1_color={post.frontmatter.kml1_color}
      kml2_color={post.frontmatter.kml2_color}
      kml3_color={post.frontmatter.kml3_color}
      kml4_color={post.frontmatter.kml4_color}
      title={post.frontmatter.title}
      cityname={city1nfd}
      
    />
  </div>
	<div className={syousai}>

  </div>
</div>


    <details>
          <summary className={syousaiheader}>
            データの詳細情報を確認する
          </summary>

<div className={csssyousaigridinner}>
  <div className={csssyousaigridchild000}>
    <div className={table}>
      <div>
        <h2>高さ</h2>
        <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th className={row1}></th>
              <th className={row2}>解説</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>名称</td>
              <td>{post.frontmatter.height_name}</td>
              </tr>
              <tr>
              <td>年次</td>
              <td>{post.frontmatter.year_display}</td>
              </tr>
              <tr>
              <td>出典</td>
              <td>{post.frontmatter.height_origin}</td>
              </tr>
              <tr>
              <td>定義</td>
              <td>{post.frontmatter.height_origin_note}</td>
              </tr>
              <tr>
              <td>詳細</td>
              <td>
                <a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin_url_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url2}>{post.frontmatter.height_origin_url2_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url3}>{post.frontmatter.height_origin_url3_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url4}>{post.frontmatter.height_origin_url4_name}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  <div className={csssyousaigridchild000}>
    <div className={tablediv}>
      <div className={table}>
       <div>
          <h2>色</h2>
          <table>
          <thead>
          <tr>
          <th className={row1}></th>
          <th className={row2}>解説</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>名称</td>
          <td>{post.frontmatter.color_name}</td>
          </tr>
          <tr>
          <td>年次</td>
          <td>{post.frontmatter.year_display}</td>
          </tr>
          <tr>
          <td>出典</td>
          <td>{post.frontmatter.color_origin}</td>
          </tr>
          <tr>
          <td>定義</td>
          <td>{post.frontmatter.color_origin_note}</td>
          </tr>
          <tr>
          <td>詳細</td>
          <td>
            <a href={post.frontmatter.color_origin_url}>{post.frontmatter.color_origin_url_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url2}>{post.frontmatter.color_origin_url2_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url3}>{post.frontmatter.color_origin_url3_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url4}>{post.frontmatter.color_origin_url4_name}</a>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
       </div>
      </div>
    </div>
  </div>
</details>

<details>
  <summary className={syousaiheader}>
      関連するデータを調べる
  </summary>

  {/* <div className={csssyousaigridinner}>
    <div className={csssyousaigridchild000}>
      <div> */}
        {post.frontmatter.tags == null ? null : (
        <div className={taglinks}>
          {post.frontmatter.tags.map((tag: string, index: number) => (
          <div className={tagbutton}>
            <Link
            key={index}
            to={`/tags/${_.kebabCase(tag)}/`}
            >
              {`${tag}`}
            </Link>
          </div>
          ))}
        </div>
        )}
      {/* </div>
    </div> */}
{/* 
    <div className={csssyousaigridchild000}>
      <div>
        <h2>
          関連ページ
        </h2>
      </div>
      <div style={{display:"flex"}}>
        <div style={{margin:"0% 5% 0% 5%"}}>
            {edges.length !== 0 && (
          <div>
            {edges.map(({ node }: any) => (
            <div key={node.fields.slug}>
              <PostCard
                title={node.frontmatter.title || node.fields.slug}
                url={node.fields.slug}
                tags={node.frontmatter.tags}
                sdgs_no={node.frontmatter.sdgs_no}
                height_name={node.frontmatter.height_name}
                color_name={node.frontmatter.color_name}
              />
            </div>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  */}
  {/* </div> */}

</details>


    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySluga($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_name
        hanrei1_color
        hanrei2_name
        hanrei2_color
        hanrei3_name
        hanrei3_color
        hanrei4_name
        hanrei4_color
        height_ratio
        year1
        year2
        year3
        year4
        year5
        try
        min
        ratio
        shikii1
        shikii2
        shikii3
        csvmode
        kml1_color
        kml2_color
        kml3_color
        kml4_color
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
            year1
            year2
            year3
            year4
            year5
            try
            min
            ratio
            shikii1
            shikii2
            shikii3
            csvmode
            kml1_color
            kml2_color
            kml3_color
            kml4_color
          }
        }
      }
    }
  }
`;

